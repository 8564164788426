/**
 * Minified by jsDelivr using clean-css v5.2.4.
 * Original file: /npm/@tarekraafat/autocomplete.js@10.2.7/dist/css/autoComplete.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
.autoComplete_wrapper {
    display: inline-block;
    position: relative;
    margin: 0;
    width: 100%
}

.autoComplete_wrapper>input {
    height: 5.6rem;
    width: 100%;
    margin: 0;
    padding: 0 5rem 0 2rem;
    box-sizing: border-box;
    text-overflow: ellipsis;
    background: $color-active;
    outline: 0;
    border-radius: .4rem;
    border: 0;
    background-image: url(../img/icons-svg/caret.svg);
    background-size: 3.2rem 3.2rem;
    background-position: right 1.45rem top .9rem;
    background-repeat: no-repeat;
    background-origin: border-box;
    transition: all .4s ease;
    color: #fff;
    box-shadow: 0px 2px .4rem -2px rgba(24, 39, 75, 0.12), 0px .4rem .4rem -2px rgba(24, 39, 75, 0.08);
    &::-webkit-input-placeholder {
    color: rgba(#fff,.7);
}

&::-moz-placeholder {
    color: rgba(#fff,.7);

}

&:-moz-placeholder {
    color: rgba(#fff,.7);
}

&:-ms-input-placeholder {
    color: rgba(#fff,.7);
}
}






.autoComplete_wrapper>ul {
    position: absolute;
    max-height: 22.6rem;
    overflow-y: auto;
    box-sizing: border-box;
    left: 0;
    right: 0;
    margin:  0;
    padding: 0;
    z-index: 1;
    list-style: none;
    border-radius: .6rem;
    background-color: #fff;
    border: 1px solid rgba(33, 33, 33, .07);
    box-shadow: 0 3px .6rem rgba(149, 157, 165, .15);
    outline: 0;
    transition: opacity .15s ease-in-out;
    -moz-transition: opacity .15s ease-in-out;
    -webkit-transition: opacity .15s ease-in-out
}

.autoComplete_wrapper>ul:empty,
.autoComplete_wrapper>ul[hidden] {
    display: block;
    opacity: 0;
    transform: scale(0)
}

.autoComplete_wrapper>ul>li {
    margin: 0;
    padding: .4rem .8rem;
    text-align: left;
    font-size: 1rem;
    color: #212121;
    border-radius: .35rem;
    background-color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .2s ease
}
.autoComplete_wrapper>ul>li + li {
    margin-top: .4rem
}
.autoComplete_wrapper>ul>li mark {
    background-color: transparent;
    color: #ff7a7a;
    font-weight: 700
}

.autoComplete_wrapper>ul>li:hover {
    cursor: pointer;
    background-color: rgba(255, 122, 122, .15)
}

.autoComplete_wrapper>ul>li[aria-selected=true] {
    background-color: rgba(255, 122, 122, .15)
}

@media only screen and (max-width:600px) {
    .autoComplete_wrapper>input {
        width: 18rem
    }
}