

.jq-file {
	width: 100%;
	display: inline-block;
    position: relative;
    overflow: hidden;
}
.jq-file input {
	cursor: pointer;
	height: auto;
	line-height: 1em;
    width: 128px;
        position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0px;
    padding: 0px;
}

.jq-file__name {
-moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    padding: 6px 0 0 0;
    color: #333;
    font: 12px/18px Arial,sans-serif;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    background: #FFF;
    position: absolute;
    left: 146px;
    top: 0;
    width: 150px;
}
.focused .jq-file__name {}
.jq-file__browse {
	position: relative;
	padding: 6px 0px;
	border: 1px solid #9aa5a6;
	border-radius: 2px;
	color: #5c6266;
	font: 13px/18px Arial;
    height: 30px;
    width: 128px;
    display: block;
    text-align: center;
    cursor: pointer;
}
.jq-file:hover .jq-file__browse {
    opacity: 0.7;
}
.jq-file:active .jq-file__browse {
    
}
.jq-file.disabled .jq-file__name {
	
}
.jq-file.disabled,
.jq-file.disabled .jq-file__browse {
	
}


.jq-selectbox {
	vertical-align: middle;
	cursor: pointer;
	position: relative;
    width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
}
.jq-selectbox__select {
	height: 5.4rem;
	padding: 16px 72px 7px 3rem;
	position: relative;

	background: #FCFCFC;
	border: 1px solid $line;
}

.jq-selectbox select, .jq-select-multiple select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    z-index: -100
}
.select_with-select-first .jq-selectbox__select{
    color: #5c6266;
}

.jq-selectbox__select-text {
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&.placeholder {
		color: $line;
	}
}
.jq-selectbox__trigger {
	position: absolute;
	top: 0;
	right: 0;
	width: 38px;
	height: 100%;

}
.jq-selectbox__trigger-arrow {
	position: absolute;
	top: 16px;
	right: 28px;
	width: 10px;
	height: 10px;
	overflow: hidden;
	border-bottom: 1px solid $text-color;
	border-left: 1px solid $text-color;
	transform: rotate(-45deg);
	transform-origin: center;
	transition: all .3s ease-in
}


.jq-selectbox__dropdown {
	top: 100%;
    margin-top: -1px;
	width: 100%;
	box-sizing: border-box;
	padding: 0;
	overflow: hidden;
	position: absolute;
	z-index: 100;
	position: relative;

	background: #FCFCFC;
	border: 1px solid $line;
}
.select_number-persons .jq-selectbox__dropdown {
    top:29px;
    margin-top: 0px;
}
.jq-selectbox__search {
	margin: 5px;
}
.select_number-persons .jq-selectbox__search{
    display: none!important;
}
.jq-selectbox__search input {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	outline: none;
	border: 1px solid #CCC;
	border-radius: 3px;
	color: #333;
	-webkit-appearance: textfield;
}
.jq-selectbox__search input[type=search]{display:none;}
.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
	-webkit-appearance: none;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 11px 0 9px;
	max-height: 175px;
	list-style: none;
	overflow: hidden auto;
}
.jq-selectbox li {
	padding: 6px 26px 8px;
	transition: color .5s ease
}
.jq-selectbox li:hover {
	color: $color-active;
}
.jq-selectbox li.selected {
	background: #EFEFEF;;
	color: $text-color;
}
.jq-selectbox li.disabled {}
.jq-selectbox li.disabled:hover {}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}

.jq-select-multiple {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,0.1);
	background: #FFF;
	color: #333;
	font: 14px/18px Arial, sans-serif;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}

