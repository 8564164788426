.window-open,
.window-open-form {
  position: absolute;
  left: 0;
  top: -200%;
  right: 0;
  overflow: hidden;
  height: 100%;
  pointer-events: none;
  opacity: 0;
}

.popup {
  max-width: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 67rem;
  box-shadow: none;
  border-radius: 0;
  background: #FFFFFF;
}

.popup .fancybox-close-small {
  position: absolute;
  font-size: 2.5rem;
  top: 2rem;
  right: 2rem;
  color: $color-active;
}

.popup .fancybox-close-small:hover {
  color: $color1;
}

.popup .box-field__button {
  margin-top: 3.6rem;
}

.popup {
  .btn {
    min-width: 28rem;
    height: 4.9rem;
    line-height: 4.9rem
  }

  .box-field__button {
    text-align: center;
    margin-top: 2.6rem
  }

  .form-title {
    color: $text-color;
    text-align: left
  }

  .box-field {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .box-field__label {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    display: block;
  }

  .box-field_half {
    width: 50%;
  }

  .contact-form {
    form {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
    }

    [type="text"],
    [type="tel"],
    [type="email"],
    [type="password"],
    textarea {
      height: 6rem;
      font-size: 1.6rem;
      padding-left: 2.3rem;
      padding-right: 2.3rem;
      border: 1px solid #D1D1D1;
      border-radius: .4rem
    }


    textarea {
      padding-top: .5rem;
    }

    .input-2_textarea {
      height: 12.4rem
    }
  }

  .box-field {
    margin-bottom: 1.7rem
  }
}

.form-text {
  color: $color-active;
  margin: -1rem 0 3.2rem;
}

.popup .btn_extra {
  width: 34rem;
  max-width: 100%;
}

.popup-inner {
  padding: 10.5rem 17% 2.9rem;
}

.popup-title {
  margin: 0 0 2.5rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-transform: uppercase;
}

.popup-sucsess {
  width: 51.9rem;
  max-width: 100%;
  text-align: center;
}

.popup-sucsess .popup-inner {
  padding: 3.1rem 4rem 4rem;
}

.succsess-text {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-top: 1.7rem;
}

.succsess-img {
  margin-bottom: 1.4rem;
}

.popup-search,
.popup-language {
  background: rgb(247, 247, 247);
  position: fixed;
  top: 5.3rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: none;
  overflow: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.shown {
    display: flex;
  }

  .h2 {
    color: $color2;
  }

  .popup-inner {
    padding: 2rem;
    margin-left: 3.6%;
    margin-right: 3.6%
  }
}


.search-form {
  position: relative;

  &-head {
    margin-bottom: .2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.search-form {
  background: $dark-bg;
  border-radius: 10rem;
}

.input-search {
  background: rgba(#fff, .5);
  border-radius: 10rem;
  padding-left: 6rem;
  height: 4.2rem;
  border: 0;

  &::-webkit-input-placeholder {
    color: $dark-gray;
  }

  &::-moz-placeholder {
    color: $dark-gray;

  }

  &:-moz-placeholder {
    color: $dark-gray;
  }

  &:-ms-input-placeholder {
    color: $dark-gray;
  }
}

.btn-search {
  background: $color2;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  color: #fff;
  font-size: 1.6rem;
  position: absolute;
  left: 1.2rem;
  top: .5rem;

}

.popup .search-form {
  [type="text"] {
    padding-left: 5.3rem;
  }

  .btn-search {
    top: 1.4rem;
  }
}

.close-searh {
  padding: 1.5rem;
  color: $color2;
  font-size: 1.9rem;
  transition: color .7s ease;
  cursor: pointer;

  &:hover {
    color: $color-active;
  }
}

.search-form_modal {
  background: transparent;

  .input-search {
    border: 1px solid $color-active;
    height: 5.6rem;
    font-size: 2rem;
    padding-left: 5.6rem;
  }

  .btn-search {
    top: 1.2rem;
    left: 1.6rem;
  }
}

.reset-btn {
  margin-top: 2.6rem;
  text-align: right;

  .btn {
    padding: 0 2.7rem;
    height: 4.9rem;
    line-height: 4.9rem;
  }
}

.popup-language {
  text-align: center;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);

  &__tile {
    color: $color2
  }

  .popup-inner {
    position: relative;
  }
}

.language-btns {
  margin: 2.8rem -1.2rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .btn {
    height: auto;
    padding: 1.5rem 2.4rem;
    line-height: 1.1;
    width: 33.4rem;
    margin: 1.2rem;
  }
}

.close-language {
  position: absolute;
  right: 4.5rem;
  top: 4.5rem;
  z-index: 2
}

.language-text {
  margin-top: 4rem
}

.cookie-popup {
  padding: 3.6rem 0 3.3rem;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 100;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.cookies {
  display: flex;
  align-items: center;
  justify-content: space-between;
    max-width: 140rem;

  &__text {
    color: $dark-gray;
    font-size: 1.2rem;
    padding-right: 5%;
    font-weight: 500;

    a {
      color: $color2;
      text-decoration: underline;

      &:hover {
        color: $color-active;
      }
    }
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    margin-right: -1, 2rem;

    .btn {
      height: auto;
      padding: 1.5rem 2.4rem;
      line-height: 1.1;
      margin: 1.2rem;
    }
  }
}

@media screen and (max-width: 1023px) {

  .popup-search,
  .popup-language {
    top: 8.6rem;
  }

  .cookie-popup {
    padding: 2.6rem 0 2.3rem;
  }

  .cookies__btns {
    flex-direction: column;
    margin: 0;

    .btn {
      margin: .8rem 0
    }
  }
}

@media screen and (max-width: 767px) {
  .popup-inner {
    padding: 8rem 2rem 2.9rem;
  }

  .popup .box-field {
    width: 100%;
  }

  .contact-form {

    [type="text"],
    [type="tel"],
    [type="email"],
    [type="password"],
    textarea {
      height: 4.5rem;
      font-size: 1.4rem
    }

    textarea {
      padding-top: 1rem;
    }


    .input-2_textarea {
      height: 10rem;
    }
  }

  .popup .box-field__label {
    margin-bottom: .7rem
  }

  .popup-search,
  .popup-language {
    top: 6.2rem;
  }

  .popup-search {
    .h2 {
      display: none;
    }
  }

  .search-form-head {
    justify-content: flex-end;
  }

  .popup-search .popup-inner {
    padding-left: .3rem;
    padding-right: .3rem;
    margin-left: 0;
    margin-right: 0;
  }

  .search-form_modal .input-search {
    height: 4.8rem;
    font-size: 1.4rem;
    padding-left: 4.9rem;
  }

  .reset-btn {
    margin-top: 2.4rem;

    .btn {
      height: 3.8rem;
      line-height: 3.8rem;
      padding: 0 1.9rem;
    }
  }

  .search-form_modal .btn-search {
    top: .7rem;
    left: .8rem;
  }

  .language-btns {
    flex-direction: column;
    margin: 1rem 0 0;

    .btn {
      margin: 0.8rem 0;
      padding: 1.1rem 2rem;
      line-height: 1.1;
      max-width: 33.4rem;
      width: 100%
    }
  }

  .popup-language .popup-inner {
    padding: 6.5rem 0 2rem;
    margin-left: 0;
    margin-right: 0;
  }

  .language-text {
    margin-top: 1.6rem;
  }

  .close-language {
    top: 0;
    right: 0
  }

  .cookie-popup {
    padding: 4.4rem 0 4.1rem;
  }

  .cookies {
    display: block;

    &__btns {
      margin: 3.5rem 0 0;
      display: block;

      .btn {
        margin: 0.8rem 0;
        padding: 1.1rem 2.5rem;
        line-height: 1.1;
      }
    }
  }
}