.section-main-about {
    padding: 10.3rem 0 9.8rem;
    position: relative;

    .wrapper {
        z-index: 1
    }
}

.h2-about {
    font-family: $custom-font-family;
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: none;
}

.main-about-description {
    columns: 2;
    column-gap: 3rem
}

.section-counts {
    padding: 5.5rem 15% 4.4rem;
}

.list-counts {
    margin: 0 -2rem;
    display: flex;
    list-style: none;

    &__item {
        width: 25%;
        padding: 0 2rem;
    }

    &__count {
        font-size: 4.8rem;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    &__title {
        margin-top: 1.6rem;
    }
}

.section-quote {
    padding: 7rem 0 9.4rem;
}

.main-quote {
    width: 90%;
    max-width: 106rem;
    padding: 0 0 0 15%;
    margin: 0 auto;
    position: relative;

    .h2 {
        margin-bottom: 1.5rem;
        transition: transform 1s ease
    }

    &>* {
        position: relative;
        z-index: 1
    }

    &__icon {
        position: absolute;
        z-index: 0;
        left: 6%;
        top: -.7rem;
        font-size: 15.6rem;
        color: $color1;
        opacity: .5;
        transition: transform 1s ease
    }

    &__description {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        text-align: right;
    }

    &:hover {
        .main-quote__icon {
            transform: translateX(-3rem);
        }

        .h2 {
            transform: translateX(4rem);
        }
    }
}

.form-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.form-head {
    width: 59%;
    padding-right: 7%;

    .h2 {
        max-width: 51rem;
    }

    &__text {
        margin-top: 3.4rem;
        max-width: 50rem;
    }

}

.form-wrap {
    width: 41%;
    background: #fff;
    border-radius: .8rem;
    padding: 4rem;
    color: $text-color;
    position: relative;

    .box-field__button {
        text-align: center;
        margin-bottom: 0
    }

    [type=text],
    [type=tel],
    [type=email],
    [type="password"] {
        padding: 0
    }
}

.form-title {
    margin-bottom: 2rem;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: $color-active;
    text-align: center;
}

.alert-form-wrap {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.box-field__input {
    position: relative;
}

.input-label {
    position: absolute;
    top: .9rem;
    left: 0;
    padding: 0;
    width: 100%;
    text-align: left;
    pointer-events: none;
    z-index: 1;
    color: $dark-gray;
    transition: margin-top .4s ease, font-size .4s ease
}

.input--filled .input-label {
    font-size: 75%;
    margin-top: -1.6rem;
}

.alert-form {
    padding-top: 1.4rem;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__text {
        text-align: center;
    }

    &__img {
        margin-bottom: 3rem;
    }

    &__col {
        width: 50%
    }

    .form-title {
        color: $text-color;
        margin-bottom: 0;
    }
}

.alert-text {
    color: $color-active;
    margin-top: 1rem;
}

.section-reviews {
    padding: 11.9rem 0 2.8rem;
    background: #F7F7F7;

    .navs-slider {
        margin-top: 6rem;
        justify-content: flex-end;
    }
}


.reviews {
    margin: 0 -1.2rem;

    &__item-wrap {
        padding: 10rem 1.2rem 1px;
        display: flex !important;
        flex-direction: column;

    }

    &__item {
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 100%;
        border-radius: .8rem;
        background: #fff;
        border: 1px solid $color1;
        padding: 0 4rem 4rem
    }

    &__top {
        margin: -10rem 0 1.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;

        i {
            color: $color1;
            font-size: 7.2rem;
            flex-shrink: 0
        }
    }

    &__img {
        margin-right: 2rem;
        width: calc(100% - 11.4rem);

        img {
            border-radius: .4rem
        }
    }

    &__description {
        flex-grow: 10;

    }

    &__head {

        &>div {
            min-width: 50%
        }
    }

    &__title {
        font-size: 1.8rem;
        color: $dark-gray;
    }

    &__position {
        font-size: 1.2rem;
        font-weight: 500;
    }

    &__text {
        margin-top: 2.1rem;
        text-indent: 3.5rem;
    }
}

.navs-slider {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: $color-active;

    &_right {
        justify-content: flex-end;
    }

    &_sm {
        .slick-arrow {
            height: 3.3rem;
            width: 3.2rem;
        }

        .slick-next {
            margin-left: 0
        }
    }

    &_vertical {
        display: flex;
        flex-direction: column;

        .slick-next {
            margin: 1rem 0 0;
        }
    }
}

.dots-slider {
    margin: 0 1.9rem;
}

.section-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.h2-category {
    font-size: 2.4rem;
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    color: $color-active;
}

.section-partners {
    padding: 8.1rem 0 7.8rem;
}

.partners-slider {
    margin-top: 6.1rem;

    .navs-slider {
        margin-top: 8.4rem;
    }
}

.partners {
    text-align: center;
    margin: 0 -2rem;

    &__item {
        padding: 1px 2rem;

        &:hover {
            .partners__img {
                filter: grayscale(0);
                transform: scale(1.05)
            }
        }
    }

    &__img {
        filter: grayscale(1);
        display: inline-block;
        transition: filter .6s ease, transform .6s ease;

        img {
            vertical-align: top;
            display: inline-block;
        }
    }

    &__title {
        line-height: 140%;
        color: $text-color;
    }
}

.section-form {
    padding: 7rem 14% 7rem;
    position: relative;

    .wrapper {
        z-index: 2
    }

    &_overflow {
        overflow: hidden;
    }
}

.decor-pattern-1 {
    left: 0;
    bottom: 0;
    width: 37%;
    max-width: 710px;
    transform: translateX(-11%);
    position: absolute;
    z-index: 0;
    background-size: auto 100%;
    background-position: 81% 100%;
    background-repeat: no-repeat;
    pointer-events: none;
max-width: 710px;
    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
}

.decor-12 {
    left: 0;
    top: -7.1rem;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    background-size: auto 100%;
    background-position: 81% 100%;
    background-repeat: no-repeat;
    pointer-events: none;
}

.section-biotechnologi {
    padding: 12.1rem 0 11.9rem;
    position: relative;
}

.biotechnologi {
    display: flex;

    &__head {
        width: 50%
    }

    &__images {
        width: 50%;
        background-size: cover;
        flex: 1 1 100%;
        position: absolute;
        z-index: 0;
        right: 0;
        top: 0;
        bottom: 0;
        video {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
            object-fit: cover;
            object-position: 50% 50%;
        }
        &:before {
            content: '';
            display: block;
            width: 30%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            bottom: 0;
            background: linear-gradient(90deg, #1F1F1F 26.55%, rgba(31, 31, 31, 0.00) 91.24%);
        }

    }
    &__img {
        position: relative;
        height: 100%
    }
    &__btn {
        margin-top: 3.7rem
    }
}

.section-garanty {
    padding: 12.2rem 0 11.4rem;
}

.garanty {
    display: flex;
    flex-wrap: wrap;

    &__img {
        width: 37%;
        position: relative;
        z-index: 1;

        img, video {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            position: absolute;
        }
    }

    &__picture {
        position: relative;

        &:before {
            content: '';
            display: block;
            padding-top: 87%
        }
    }

    &__head {
        width: 57%;
        padding: 0 1rem 0 2.4rem;
        position: relative;
        z-index: 0
    }

    .section-head {
        max-width: 45rem;
    }

    &__slider {
        padding-left: 3.2rem;
        margin: 2rem 0 0 -7.4rem;
        background-color: $color-active;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        width: 100%;
        border-radius: .4rem
    }

    &__item {
        font-size: 3.2rem;
        line-height: 120%;
        color: #ffff;
        padding: 7.4rem 4rem 6.9rem;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 100%;
        min-height: 24.6rem;
        font-family: $custom-font-family
    }

    .navs-slider {
        flex-shrink: 0
    }

    .slick-slider {
        width: calc(100% - 10rem)
    }

    .slick-slide {
        display: flex;
        flex-direction: column;

        &>div {

            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
        }
    }

    .slick-arrow {
        color: #fff;
        border-color: #fff;
    }
}

.web .garanty .slick-arrow:hover {
    color: $text-color;
    border-color: $text-color;
}

.section-agroservice {
    position: relative;
}

.slider-11__item .wrapper {
    padding-top: 7.9rem;
    z-index: 1
}

.agroservice {
    display: flex;

    &__head {
        width: 51%;
        padding-right: 7%;
        padding-bottom: 32rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .h2 {
            margin-bottom: 0
        }
    }

    &__images {
        width: 49%;
        display: flex;
        padding: 0 0 7rem 4.4rem;
        position: relative;
    }

    .section-head {
        margin-bottom: 4.6rem;
    }

    &__btn {
        margin-top: 2.6rem
    }

    &__tab {
        max-width: 58rem
    }

    &__tab+&__tab {
        margin-top: 8.1rem
    }

    &__decor-1 {
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -1px;
        bottom: -1.3rem;
        width: 37%;

        &:before {
            content: '';
            padding-top: 41%;
            display: block;
        }
    }

    &__decor-2 {
        position: absolute;
        left: 50%;
        top: -5.8rem;
        transform: translateX(-50%);
        z-index: 0;
        width: 29%;
        z-index: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 0;

        &:before {
            content: '';
            padding-top: 142%;
            display: block;
        }
    }

    &__decor-3 {
        padding-right: .7rem;
        width: 50%;
        position: relative;
        z-index: 1;
        padding-top: 22%;

        img {
            border-radius: .4rem;
            width: 100%;
        }
    }

    &__decor-4 {
        position: relative;
        z-index: 1;
        padding-left: .7rem;
        width: 50%;

        img {
            border-radius: .2rem;
            width: 100%;
        }
    }

    &__title {
        font-size: 3.2rem;
        color: inherit;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        i {
            display: flex;
            font-size: 35%;
            height: 5.6rem;
            width: 5.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-left: 2.5rem;
            background: $color1;
            color: $text-color;
            transition: background .5s ease;
            cursor: pointer;

            &:hover {
                background: $color-active;
            }

            &:before {
                display: inline-block;
                vertical-align: top;
                transition: transform .5s ease;
            }
        }
    }

    &__text {
        margin-top: 2.5rem;
    }
}

.active {
    .agroservice__title i:before {
        transform: rotateX(180deg);
    }
}

.slider-11 {
    .slick-slide {
        display: flex;
        flex-direction: column;

        &>div {
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
    }

    &__item {
        flex: 1 1 100%;
        position: relative;
        display: flex !important;
        flex-direction: column;
    }
}

.section-news {
    padding: 8.2rem 0 7rem;
    overflow: hidden;
}

.news {
    margin: 0 -1.2rem;

    &__item-wrap {
        padding: 1px 1.2rem;

        .news__item {
            padding: 0
        }
    }

    .slick-track {
        margin-left: 0;
        margin-right: 0
    }

    .slick-list {
        overflow: visible;
    }

    &__item {
        border-radius: 2.1rem 2.1rem 1.6rem 1.6rem;
        overflow: hidden;
        background: #fff;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
    }

    &__img {
        position: relative;
        display: block;

        img {
            width: 100%;
            position: absolute;
            height: 100%;
            left: 0;
            object-position: 50% 50%;
            object-fit: cover;
            top: 0;
        }

        &:before {
            content: '';
            display: block;
            padding-top: 59.83%
        }
    }

    &__description {
        padding: 2.3rem 2.6rem 4.4rem;
        position: relative;
        flex-grow: 10
    }

    &__categories {
        margin-right: -1rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__category {
        margin: 0 1rem .8rem 0;
        padding: .5rem .7rem .6rem;
        font-size: 1.2rem;
        line-height: 1.1;
        font-weight: 500;
        display: inline-block;
        border-radius: 10rem;
        border: 1px solid $color1;
        color: $color1;
        transition: background .5s ease, color .5s ease;

        &:hover {
            color: $text-color;
            background: $color1
        }
    }

    &__title {
        font-size: 1.7rem;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;

        a {
            color: $text-color
        }
    }

    &__date {
        color: #D1D1D1;
        font-size: 1.2rem;
        font-weight: 500;
        position: absolute;
        left: 2.6rem;
        right: 2.6rem;
        bottom: 2rem;
    }
}

.filter-categories {
    margin: 1.9rem -1.2rem 2rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.filter-cat {
    border-radius: 10rem;
    background: rgba(255, 255, 255, 0.50);
    font-size: 1.8rem;
    padding: .7rem 2.5rem;
    font-weight: 400;
    margin: 0 1.2rem 1rem 0;
    color: $text-color;

    &:hover,
    &.active {
        background: $color1
    }

}

.news.slick-slider {

    .slick-slide {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;

        &>div {
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
        }
    }

    .news__item {
        flex: 1 1 100%;
    }
}

.form-submit {
    padding: 0 2.9rem
}

@media screen and (max-width: 1440px) {
    .section-biotechnologi {
        padding: 9.1rem 0 7.9rem;
    }

    .section-garanty {
        padding: 7.2rem 0 7rem;
    }

    .section-main-about {
        padding: 7.3rem 0 6.8rem;
    }
}

@media screen and (max-width: 1366px) {
    .partners-slider .navs-slider {
        margin-top: 6.4rem;
    }

    .section-partners {
        padding: 7.1rem 0 6.8rem;
    }

    .garanty__item {
        padding: 3.4rem 3rem 2.9rem;
        font-size: 3rem;
    }

    .list-counts__count {
        font-size: 4.2rem
    }

    .h2-about {
        font-size: 5.6rem;
    }

    .agroservice__head {
        padding-bottom: 27rem
    }
}

@media screen and (max-width: 1300px) {
    .section-counts {
        padding-left: 9%;
        padding-right: 9%;
    }

    .section-form {
        padding-left: 10%;
        padding-right: 10%
    }

    .form-head {
        width: 54%;
    }

    .form-wrap {
        width: 46%;
    }
    
    .section-form {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (max-width: 1023px) {
    .reviews__top i {
        font-size: 4.8rem
    }

    .agroservice__images {
        display: none;
    }

    .agroservice__head {
        width: 100%;
        padding-right: 0;
        padding-bottom: 0
    }

    .garanty__item {
        font-size: 2.3rem;
        padding: 4.4rem 3rem 3.9rem 4rem;
        min-height: 17rem
    }

    .agroservice__title-1 {
        margin-top: 3rem;
    }

    .agroservice__btn-2 {
        margin-top: 5rem;
    }

    .partners-slider {
        margin-top: 4.3rem;
    }

    .section-news {
        padding: 6.6rem 0 6rem;
    }

    .form-head {
        width: 53%;
        padding-right: 16%;
    }

    .form-wrap {
        width: 47%;
    }

    .garanty__slider {
        width: calc(100% + 7.4rem);
    }

    .reviews {
        &__item {
            padding: 0 3rem 3rem;
        }

        &__head {
            min-height: 4.5rem
        }

        &__text {
            margin-top: 2.6rem;
            text-indent: 2.9rem
        }
    }

    .h2-about {
        font-size: 4.6rem;
    }

    .decor-pattern-1 {
        display: none;
    }

    .slider-11__item .wrapper {
        padding-top: 4.9rem;
        padding-bottom: 4rem
    }
}

@media screen and (max-width: 767px) {
    .agroservice__decor-5 {
        width: 12.9rem;
        position: absolute;
        z-index: 0;
        right: 0;
        top: 0;
        background-size: 100% auto;

        &:before {
            content: '';
            padding-top: 120%;
            display: block;
        }
    }

    .agroservice__decor-1 {
        display: none;
    }

    .slider-11__item {
        .wrapper {
            padding-top: 3.9rem;
            padding-bottom: 3rem
        }

        .h2 {
            max-width: 65%
        }
    }

    .h2-about {
        font-size: 2.4rem;
    }

    .main-about-description {
        columns: 1;
        line-height: 140%
    }

    .section-main-about {
        padding: 3.5rem 0 2.8rem;
    }

    .h2-about {
        margin-bottom: 0
    }

    .section-counts {
        padding: 3.1rem 0 4.4rem;
    }

    .list-counts {
        display: block;
        text-align: center;

        &__count {
            font-size: 2.6rem;
            line-height: 1.1
        }

        &__title {
            margin-top: 0.2rem;
        }

        &__title {
            margin: 0 auto;
            max-width: 75%;
        }

        &__item {
            width: 100%;

            &+& {
                margin-top: 2.5rem
            }
        }
    }

    .reviews {
        .slick-slide {
            padding-top: 8rem
        }

        &__top {
            margin: -8rem 1.4rem .9rem;
        }

        &__top i {
            font-size: 4.5rem;
            margin-top: 3.3rem;
        }

        &__img {
            margin-right: 1.1rem;
        }

        &__head,
        &__img {
            width: calc(100% - 6.5rem);
        }

        &__item {
            padding: 0 .6rem 4rem
        }

        &__title {
            font-size: 1.2rem;
        }

        &__position {
            font-size: .8rem;
        }

        &__text {
            margin-top: 2.2rem;
        }
    }

    .main-quote {
        padding: 0 0 0 28%;
        width: 100%
    }

    .dots-slider {
        font-size: 1.2rem;
        margin: 0 .9rem;
    }

    .navs-slider {
        margin-top: 1rem;
        justify-content: flex-end;
    }

    .navs-slider_sm .slick-arrow {
        height: 4rem;
        width: 4rem;
    }

    .section-reviews {
        padding: 3.8rem 0 4.2rem;
    }

    .section-quote {
        padding: 5.6rem 0 4.4rem;
    }

    .main-quote .h2 {
        font-size: 2.6rem;
        margin-bottom: 1.5rem;
        text-align: right;
    }

    .main-quote__description {
        font-size: 1.6rem;
    }

    .main-quote__icon {
        left: -1%;
        top: -1.7rem;
        font-size: 9.2rem;
    }

    .main-quote:hover {
        .h2 {
            transform: translateX(5rem);
        }

        .main-quote__icon,
        .h2 {
            transform: translateX(0);
        }
    }

    .section-biotechnologi {
        padding: 4.1rem 0 0;

        .h2 {
            font-size: 3.2rem;
            margin-bottom: 0.2rem;
        }
    }

    .biotechnologi__btn {
        margin-top: 0.7rem;

        .btn-2 {
            font-size: 1.4rem;
            height: 2.6rem;
            line-height: 2.6rem;
            padding: 0 1.6rem;
        }
    }

    .biotechnologi {
        display: block;
    }

    .biotechnologi__head {
        width: 100%;
    }

    .biotechnologi__images {
        margin-top: 2rem;
        position: static;
        width: 100%;

        &:before {
            content: '';
            display: block;
            position: static;
            width: 100%;
            padding-top: 80%;
            height: auto;
            background: linear-gradient(180deg, #1F1F1F -1%, rgba(31, 31, 31, 0) 51.24%);
        }
    }

    .section-garanty {
        padding: 4.1rem 0 4rem;
    }

    .garanty {
        margin: 2.3rem -2rem 0;
        display: block;
        position: relative;

        .slick-arrow {
            height: 4rem;
            width: 4rem;
        }

        .navs-slider {
            justify-content: flex-end;
            padding-right: 2rem;
            margin-top: .5rem
        }
    }

    .garanty__slider {
        width: 100%;
        border-radius: .4rem 0 0 .4rem
    }

    .garanty__img {
        width: 100%;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        bottom: 5.5rem;
        right: 0
    }

    .garanty__picture {
        height: 100%;
    }

    .garanty__head {
        max-width: 91rem;
        width: 100%;
        padding: 2.1rem 0 0 2rem;
    }

    .garanty__slider {
        padding: 0 0 3rem;
        margin: 2rem 0 0;
        display: block;
    }

    .garanty .slick-slider {
        width: 100%;
    }

    .garanty__item {
        font-size: 2rem;
        padding: .5rem 2rem .5rem 2rem;
        min-height: 0;
        justify-content: flex-start;
    }

    .navs-slider_vertical {
        flex-direction: row;
    }

    .navs-slider_vertical .slick-next {
        margin: 0 0 0 1.2rem;
    }

    .agroservice__title {
        font-size: 1.8rem;
        margin-top: 2rem
    }

    .agroservice__title i {
        height: 4rem;
        width: 4rem;
    }

    .agroservice__text {
        margin-top: 0rem;
    }

    .agroservice__btn {
        margin-top: 1rem;

        .btn-2 {
            font-size: 1.4rem;
            height: 2.6rem;
            line-height: 2.6rem;
            padding: 0 1.6rem;
        }
    }

    .agroservice__btn-2 {
        margin-top: 1rem;
    }

    .agroservice__title i {
        margin-left: 4.5rem
    }


    .section-head {
        display: block;

        .btn-3 {
            font-size: 1.5rem;
            padding: 0 1.8rem;
            height: 3.6rem;
            line-height: 3.6rem;
        }
    }

    .section-news {
        padding: 4.2rem 0 1.3rem;
    }

    .h2-category {
        font-size: 1.4rem;
        margin-bottom: .7rem;
    }

    .filter-categories {
        display: none;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: auto;
        margin: 0 -1.2rem 1rem 0;
    }

    .filter-cat {
        font-size: 1.2rem
    }

    .news__category {
        font-size: 1rem
    }

    .form-wrapper {
        display: flex;
    }

    .form-head {
        width: 100%;
        padding-right: 0
    }

    .form-wrap {
        margin: 3.6rem 0 0;
        padding: 2rem;
        width: 100%;

        [type=text],
        [type=tel],
        [type=email],
        [type="password"] {
            height: 3.6rem;
        }
    }

    .form-title {
        font-size: 1.8rem;
        text-align: left;
        margin-bottom: .5rem;
    }

    .decor-2,
    .slider-4-wrap .navs-slider {
        display: none;
    }

    .section-form {
        padding: 2.8rem 0 4.1rem;

        .h2 {
            max-width: 90%;
            margin-bottom: .6rem
        }
    }

    .form-head__text {
        margin-top: .4rem;
    }

    .decor-12 {
        top: 2.9rem;
        background-position: 22% 100%;
    }

    .news__description {
        padding: 1.9rem 2.2rem 3rem;
    }

    .news__date {
        font-size: .8rem;
        left: 2.2rem;
        right: 2.2rem;
        bottom: 1.7rem;
    }

    .slider-4-wrap {
        margin: 2rem 3rem 0
    }
    .news__img:before {
        padding-top: 51.83%;
    }
    .news__item-wrap {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
    }

    .slider-4-wrap {
        margin: 1rem 3rem 0
    }
    .section-partners {
        padding: 4.1rem 0 3.8rem;
    }

    .partners-slider {
        margin-top: 2.7rem;

        .navs-slider {
            margin-top: 2.4rem;
        }

        .slick-arrow {
            height: 4rem;
            width: 4rem;
        }
    }

    .agroservice .section-head {
        margin-bottom: 1.6rem;
    }

    .agroservice__tab+.agroservice__tab {
        margin-top: 1.1rem;
    }

    .agroservice__title i {
        font-size: 40%
    }

    .section-reviews .navs-slider {
        margin-top: 1rem;
    }

    .agroservice__tab {
        position: relative;
        z-index: 1
    }
}

@media screen and (min-width: 1366px) {
    .section-reviews {
        .wrapper {
            padding-right: 5.2%;
            padding-left: 5.2%;
        }
    }
}

@media screen and (min-width: 1440px) {
    .section-agroservice {
        .wrapper {
            padding-right: 8%;
            padding-left: 8%;
        }
    }
}

@media screen and (min-width: 2100px) {
.decor-pattern-1,.decor-2, .decor-10 {
    display: none;
}
.decor-12 {
        background-position: 55% 100%;
}
}