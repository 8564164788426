.section-exellence {
	padding: 12rem 0 9rem;
}

.exellence {
	margin: -1rem -5.2rem 0;
	display: flex;
	flex-wrap: wrap;

	&__item {
		margin: 1.2rem 5.2rem 0;
		padding: 3.2rem 2.1rem 2.2rem 0;
		width: calc(50% - 10.4rem);
		display: flex;
		align-items: flex-start;
		&:hover {
			.exellence__count {
				-webkit-text-fill-color: $color1;
			}
		}
	}


	&__count {
		-webkit-text-fill-color: transparent;
		-webkit-text-stroke-color: $color1;
		-webkit-text-stroke-width: 1px;
		font-weight: 700;
		font-size: 6.3rem;
		display: block;
		line-height: 1;
		margin: -.8rem 0 .6rem;
		flex-shrink: 0;
		width: 12rem;
		transition: all .6s ease
	}

	&__title {
		font-size: 3.2rem;
		font-weight: 700;
		color: #EE1B00;
	}

	&__head {
		display: flex;
		align-items: center;
	}

	&__description {
		padding: 4.8rem 0 0;

		strong {
			color: $color1;
			font-weight: 700;
		}

		b {
			font-weight: 700;
		}
	}

	&__description p:first-child {
		padding-top: 0;
	}

	&__description p:last-child {
		padding-bottom: 0;
	}

	&__description ul {
		list-style-position: outside;
		margin-left: 2.7rem;
	}

	&__description li+li {
		margin-top: 1.6rem;
	}

	&__text {
		border-top: 1px solid $color1;
		padding-top: 1.1rem;
	}
}

.section-gallery {
	padding: 5.9rem 0 7rem;

	.wrapper {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0
	}
}

.gallery {
	display: flex;
	margin: -.2rem;
	flex-wrap: wrap;

	&__item {
		margin: .2rem;
		width: calc(25% - .4rem);
		img {
			width: 100%	
		}
	}
}


.box-paging-load {
	margin-top: 6.1rem;
	text-align: center;
}

.box-paging {
	margin-top: 6.1rem;
	border-top: 1px solid #D1D1D1;
	padding-top: 4rem;
}

.box-paging ul {
	list-style: none;
	margin: 0 -.5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.box-paging li {
	margin: 0 .4rem 1rem;
}

.box-paging a,
.box-paging span {
	width: 3.2rem;
	height: 3.2rem;
	background: #fff;
	color: $text-color;
	border-radius: .4rem;
	border: 1px solid #D1D1D1;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: border-color .4s ease;

	&:hover {
		border-color: $dark-gray;
	}
}

.box-paging .active a {
	border-color: $dark-gray;
}


.box-paging span.dots {
	border: 0;
	font-size: .6rem;
	pointer-events: none;
}

.section-more-products {
	padding: 7rem 0 7rem
}

.more-slider {
	margin: 3rem -1.2rem 0;

	.slick-list {
		overflow: visible;
	}

	.slick-slide {
		padding: 2px 1.2rem
	}

	.categories-list__item {
		margin: 0;
		width: 100%
	}
}

.section-about-quote {
	background-repeat: no-repeat;
	background-position: 50% 0%;
	background-size: auto 200%;
	color: #fff;
	position: relative;
    animation: slide 17s linear infinite;

	.wrapper {
		z-index: 1;
		padding: 18rem 0 5rem;
		min-height: 65.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&:before {
		position: absolute;
		content: '';
		display: block;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		background: rgba(#1F1F1F, 0.6)
	}

	.about-quote {
		padding-left: 11%
	}

	.about-quote__icon {
		left: 12%;
		top: -11.3rem;
	}
}
@keyframes slide {
    from {
        background-position: 0 0;
    }
    50%  {
        background-position: 0 100%;
    }
    100% {
        background-position: 0 0;
    }
}
.about-quote {
	width: 90%;
	max-width: 106rem;
	padding: 0 0 0 15%;
	margin: 0 auto;
	position: relative;

	.h2 {
		margin-bottom: 2.5rem
	}

	&>* {
		position: relative;
		z-index: 1
	}

	&__icon {
		position: absolute;
		z-index: 0;
		left: 6%;
		top: -.7rem;
		font-size: 17.7rem;
		color: $color1;
		opacity: .8
	}

	&__description {
		font-size: 3.2rem;
		font-weight: 700;
		line-height: 120%;
		text-transform: uppercase;
		text-align: right;
	}
}

.section-mission {
	padding: 6rem 0 0;
	position: relative;
}

.mission {
	max-width: 1320px;
	padding: 6.6rem 7.5%;
	margin: 0 auto;
	border-radius: .8rem .8rem 0px 0px;
	border-top: 1px solid #C0E021;
	border-right: 1px solid #C0E021;
	border-left: 1px solid #C0E021;
	background: var(--White, #FFF);
	box-shadow: 0px .8rem 1.8rem -.6rem rgba(24, 39, 75, 0.12), 0px 1.2rem 4.2rem -.4rem rgba(24, 39, 75, 0.12);
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1;

	&__head {
		padding-right: 4rem;
		width: 33%
	}

	&__description {
		width: 67%
	}
}
.decor-14_2 {
	width: 7rem;
	height: 5.5rem;
	position: absolute;
	z-index: 0;
	right: 1%;
    bottom: 9rem;
	background-size: contain;
}
.section-our-activity {
	padding: 0 0 2rem;
	position: relative;
}

.our-activity {
	position: relative;
	z-index: 1;
	max-width: 1324px;
	padding: 8rem 7.8% 6.4rem;
	margin: 0 auto;
	border-radius: 0px 0px .8rem .8rem;
	border-right: 1px solid #C0E021;
	border-bottom: 1px solid #C0E021;
	border-left: 1px solid #C0E021;
	background: var(--White, #FFF);
	box-shadow: 0px .8rem 1.8rem -.6rem rgba(24, 39, 75, 0.12), 0px 1.2rem 4.2rem -.4rem rgba(24, 39, 75, 0.12);

	&__head {
		text-align: center;
	}

	&__description {
		margin-top: 2.5rem;
		columns: 2;
		column-gap: 3rem;
		line-height: 137%;

		p {
			break-inside: avoid;
			width: 100%;
			display: inline-block;
		}
	}
}

.decor-14 {
	position: absolute;
	top: -7rem;
    left: 10rem;
	width: 28.5rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-15 {
	position: absolute;
	top: 50%;
	left: -4.2rem;
	width: 14rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-17 {
	position: absolute;
    top: -5.6rem;
    left: 2rem;
    width: 25%;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}
.decor-17_3 {
top: 13.4rem;
    left: -8.2rem;
    width: 25%;
}
.decor-17_4 {
	left: -5.5%;
	    top: 5.4rem;
}
.decor-17_5 {
	left: -5.5%;
	    top: 5.4rem;
	     width: 33%;
	     max-width: 601px;
}
.decor-18 {
	position: absolute;
	top: 25%;
	left: 8.8rem;
	width: 14rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}
.decor-18_3 {
    top: 80rem;
    left: -1rem;
}
.decor-18_5 {
    top: 85rem;
    left: -1rem;
}
.decor-48 {
	left: auto;
	right: -12rem;
	top: auto;
	bottom: 4rem
}
.decor-19 {
	position: absolute;
	top: 75%;
	left: -4.2rem;
	width: 45.5rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-20 {
	position: absolute;
	top: 3%;
	right: 0;
	width: 79.5rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}
.more_btn {
	display: inline-block;
	margin-top: 3rem;
	color:#72AD43;
	text-transform: uppercase;
	cursor: pointer;
	transition: color .5s ease;
	cursor: pointer;
	&:hover {
		color: $color1;
	}
}
.more-list {
	p:last-child {
		padding-bottom: 0;
	}
	&.js-more-list {	
	max-height: 19.5rem;
	overflow: hidden;
	transition: max-height 1s ease;
	}
}
.decor-21 {
	position: absolute;
	top: 55%;
	right: -4.2rem;
	width: 45.5rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-22 {
	position: absolute;
	bottom: 0%;
	right: -4.2rem;
	width: 45.5rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.product__characteristics {
	padding-top: 6rem;
	padding-bottom: 2.4rem
}

.product-characteristics {
	margin: 4.5rem -4rem 0 0;
	display: flex;
	flex-wrap: wrap;

	&__item {
		width: 50%;
		padding: 0 4rem;
		margin-bottom: 5.5rem;
		line-height: 120%;
		display: flex;
	}

	&__icon {
		margin-right: 2.4rem;
		margin-top: 1.6rem;
		flex-shrink: 0;
		width: 2.4rem;
		height: 2.4rem;
		transform: rotate(45deg);
		background: $color2;
		border-radius: .4rem
	}

	&__value {
		font-weight: 700;
		text-transform: uppercase;
	}

	&__label {
		margin-bottom: .3rem
	}
}

.product-article {
	padding-top: 6rem;
	padding-bottom: 6rem;

	h3,
	h2 {
		text-align: left
	}

	h3 {
		padding-top: 0;
		margin-top: 6rem;

		&:first-child {
			margin-top: 0
		}

		;
	}
}

.list-3 {
	ul {
		list-style: none;
	}

	li {
		display: flex;
		align-items: center;
	}

	li+li {
		margin-top: 1.7rem;
	}

	&__icon {
		margin-right: 3.7rem;
		width: 7.3rem;
		text-align: center;
		flex-shrink: 0;
		img, svg {
			max-height: 100%;
			max-width: 100%
		}
	}
}

.section-morphological-agronomic-characteristics {
	padding-top: 6rem;
}

.morphological-agronomic-characteristics {
	margin: 8.3rem -3rem 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;

	&__item {
		padding: 0 3rem;
		margin-bottom: 10rem;
		width: 33.33%;
	}

	&__icon {
		position: relative;
		width: 12rem;
		height: 12rem;
		margin: 0 auto 3.5rem;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 50%;
			border-radius: .6rem;
			top: 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
			background: #F7F7F7;
			text-transform: 50% 50%
		}

		img, svg {
			position: relative;
			z-index: 1;
			max-width: 80%;
			max-height: 80%
		}
	}

	&__value {
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 140%;
		text-transform: uppercase;
	}
}

.section-profit-structure {
	padding-top: 6rem;
	padding-bottom: 4rem;
	border-top: .8rem solid #F7F7F7;
}

.section-product+.section-more-products {
	background: #F7F7F7;
}

.profit-structure {
	margin: 4rem -1.2rem 0;
	display: flex;
	flex-wrap: wrap;

	&__item {
		padding: .9rem 1px;
		margin: 0 1.2rem 2.4rem;
		width: calc(50% - 2.4rem);
		display: flex;
		align-items: center;
		background: #F7F7F7
	}

	&__icon {
		margin-right: 2.4rem;
		width: 7.3rem;
		height: 6rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__value {
		font-size: 2.4rem;
		font-weight: 700;
		text-transform: uppercase;
	}
}

.product__resistance {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.product-resistance {
	&__item {
		padding: 1.3rem 2.2rem 1.3rem 3.4rem;
		display: flex;
		flex-wrap: wrap;

		&:nth-child(even) {
			background: rgba(255, 255, 255, 0.15);
		}
	}

	&__head {
		padding-right: 3rem;
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		font-weight: 700;
		margin-right: 2rem;
	}

	&__value {
		font-weight: 700
	}

	&__icons {
		width: 50%;
		display: flex;
		align-items: center;
	}

	&__icon {
		flex: 1 1 100%;
		margin-right: 2rem;
		background-image: url(../img/ic_2.svg);
		background-size: contain;
		background-position: left center;
		background-repeat: no-repeat;

		&:before {
			content: '';
			display: block;
			padding-top: 100%
		}

		&.active {
			background-image: url(../img/ic_1.svg);
		}
	}
}

.filter-head__close {
	position: relative;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	height: 1.8rem;
	width: 1.8rem;
	padding: .2rem
}

.filter-head__close:before,
.filter-head__close:after {
	content: '';
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	background: #1d1d1d;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.filter-head__close:before {
	height: 1.2rem;
	width: 1px;
}

.filter-head__close:after {
	height: 1px;
	width: 1.2rem;
}

.decor-23 {
	position: absolute;
	top: 35%;
	left: 0;
	width: 39.7rem;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-24 {
	position: absolute;
	bottom: 0%;
	right: 0;
	width: 38%;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-25 {
	position: absolute;
	top: 80rem;
	left: 5.5%;
	width: 29.8rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-26 {
	position: absolute;
	top: 50rem;
	left: 0;
	width: 14rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-27 {
	position: absolute;
top: 202rem;
    left: 4%;
	width: 14rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-28 {
	position: absolute;
	top: 269rem;
	left: 1%;
	width: 47.3rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-29 {
	position: absolute;
    top: 364rem;
    left: 11%;
    width: 19rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-30 {
	position: absolute;
    top: 463rem;
	left: 0;
	width: 62rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-31 {
	position: absolute;
    top: 16.4rem;
    right: 11%;
    width: 8rem;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-32 {
	position: absolute;
    top: 152rem;
    right: 2.5%;
    width: 23%;
    max-width: 442px;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.decor-33 {
	position: absolute;
    top: 334rem;
    right: 0;
    width: 57%;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: contain;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		padding-top: 100%
	}
}

.section-exellence-about {
	background: #F7F7F7;
	padding: 5.1rem 2% 8rem;

}

.exellence-about {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -3rem;
	justify-content: center;

	&__item {
		margin: 3rem;
		padding: .1rem 0;
		width: calc(33.33% - 6rem);
		display: flex;
		flex-direction: column;
	}

	&__img {
		margin: 0 4rem;
		position: relative;
		border-radius: 50% 50% 50% 0;
		overflow: hidden;
		flex-shrink: 0;

		&:before {
			content: '';
			display: block;
			padding-top: 100%
		}

		img {
			vertical-align: top;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: 50% 50%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	&__description {
		padding: 14.2rem 4rem 1.7rem 4rem;
		border-radius: .8rem;
		border: 1px solid $color1;
		background: #FFF;
		margin-top: -12rem;
		flex: 1 1 100%;
	}

	&__title {
		font-size: 3.2rem;
		font-weight: 700;
		line-height: 120%;
		text-transform: uppercase;
		color: $color-active;
	}

	&__text {
		margin-top: 1.5rem;
	}
}


.accordion-body {
	display: none;
}

.exellence_2 {
	margin: 0;

	.exellence__item {
		width: 100%;
		margin: 0;

		&+& {
			margin-top: 4rem
		}
	}
}

.exellence-agroservice {
	margin-top: 6rem;
	padding: 8rem 6rem 7rem;

	.h2 {
		margin-bottom: 2.4rem
	}

	.exellence__item {
		padding: 1rem 0 1rem;
	}
}

.section-how-agroservice {
	padding: 5rem 0 5rem
}

.agroservices-tabs {
	.list-arrow {
		margin: 0 0 3rem;

		&:first-child {
			margin: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.map-1 {
		margin-bottom: 0;
	}
}

.how-agroservice {
	columns: 2;
	column-gap: 2.4rem;
	margin-top: 7.6rem;

	&__item {
		display: inline-block;
		break-inside: avoid;
		width: 100%;
		margin: 0 0 6.3rem;
		background: #fff;
		padding: 0 4rem 2.4rem;
		border: 1px solid $color1;
		border-radius: .6rem
	}

	p {
		padding: .7rem 0;
	}

	&__count {
		font-size: 9.6rem;
		color: $color1;
		font-weight: 700;
		line-height: 100%;
		margin: -3.5rem 0;
	}

	&__description {
		margin-top: .5rem;
	}

	&__title {
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 140%;
		text-transform: uppercase;
	}

	&__agroservice__text {
		margin-top: 1.5rem
	}

	&__head {
		margin-top: -6rem
	}

	&__text {
		margin-top: 1.4rem
	}
}

.note-text {
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 140%;
}

.marker-description {
	padding: 0 1rem 1rem;
	margin-top: .5rem;
	background: transparent;
}

.marker-header {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 2.4rem;
	padding-right: 3rem
}

.marker-text {
	margin-top: 1rem;
	color: $color-active;
	font-size: 2rem
}

.map-3 {
	height: 72rem
}

.agroservices-tabs_main {
	max-width: 105rem;
	margin: 9rem auto 0;
}
.section-seo {
	padding: 10rem 0 2.9rem;
}
.block-text {
	line-height: 1.7;
	&__wrap {
		overflow: hidden;
	    max-height: 21rem;
	    position: relative;
	    transition: max-height .7s ease;
	    &.expanded {
	    	&~ .more_btn_2 {
		    	i {
		    		transform: rotateX(0deg);
		    	}
		    }
		    &:before {
		    	opacity: 1;
		    }
	    }
	    &:before {
	    	content: '';
	    	display: block;
	    	position: absolute;
	    	left: 0;
	    	bottom: 0;
	    	right: 0;
	    	height: 9rem;
	    	pointer-events: none;
	    	transition: opacity .8s ease;
	    	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	    	opacity: 0;
	    }
	}
	ul, ol {
		margin: 1rem 0 1.1rem 2.9rem;
	}
	li + li {
		margin-top: 1px;
	}

	h2 {
		font-size: 3.2rem
	} 
	h3, h4, h5, h6  {
		font-size: inherit;
		    padding: 2rem 0 1.5rem;
	}
	h2, h3, h4, h5, h6 {
		text-align: left
	}
}
.more_btn_2 {
	color: $text-color;
	display: flex;
	align-items: center;
	font-weight: 600;
	i {
		font-size: 57%;
		margin-left: 1.2rem;
	    transition: transform 0.7s ease;
	    display: inline-block;
	     vertical-align: top;
    	transform-origin: 0 50%;
		    		transform: rotateX(180deg);
	}
	&:hover {
		color: $color1;
	}
}
.section-seo_gray {
	    background: #F7F7F7;
	    .block-text__wrap:before {
	    	background: linear-gradient(180deg, rgba(#F7F7F7,0) 0%, rgba(#F7F7F7,1) 100%);
	    }
}
.section-more-products_gray {
	background: #F7F7F7;
}
.decor-122 {
	bottom: 1.1rem;
    transform: translateY(100%);
    right: -10.2rem;
    width: 29.5rem;
}
@media screen and (max-width: 1560px) {
	.product-resistance__icon {
		margin-right: 1rem
	}
}

@media screen and (max-width: 1366px) {
	.morphological-agronomic-characteristics__item {
		width: 50%;
	}
	.product-characteristics__item {
		padding-left: 0
	}
	.morphological-agronomic-characteristics__item {
	    margin-bottom: 8rem;
	}
	.morphological-agronomic-characteristics__icon {
	    width: 11rem;
	    height: 11rem;
	    margin-bottom: 3rem;
	}
}


@media screen and (max-width: 1559px) {
	.exellence-about__title {
		font-size: 2.9REM
	}
}
@media screen and (max-width: 1279px) {
	.exellence-about {
		margin: 0 -2.4rem;
		&__item {
			margin: 2.4rem;
			width: calc(50% - 4.8rem);
		}
	}
	.section-form {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (max-width: 1023px) {

	.exellence {
		margin: 0;
	}

	.exellence__item {
		margin: 1.2rem 0;
		padding: 3.2rem 2.1rem 3.7rem 1.5rem;
		width: 100%;
	}

	.agroservices-tabs .list-arrow {
		margin-top: 2rem
	}
	.section-about-quote .wrapper {
	    padding: 16rem 0 3rem;
	    min-height: 47.5rem;
	}
	.decor-23, .decor-24 {
		display: none;
	}
	.product-resistance__head {
		font-size: 2.2rem
	}
	.product-resistance__icons {
	    width: 41rem;
	}
	.product-resistance__head {
		width: calc(100% - 41rem)
	}
	.agroservices-tabs_demo {
		.tab + .tab {
			margin-top: 1.2rem
		}
	}
}

@media screen and (max-width: 767px) {
	.section-exellence {
		padding: 4rem 0 3.3rem;
	}

	.exellence {

		&__item {
			margin: .5rem 0 0;
			padding: .2rem 0;
		}

		&__text {
			border-top: 1px solid #C0E021;
			padding-top: .7rem;
		}

		&__count {
			font-size: 4rem;
			width: 6.2rem;
			margin-right: 0
		}

		&__description {
			padding-top: 2.8rem
		}
	}


	.section-more-products {
		padding: 2.4rem 0 4.1rem;
	}

	.morphological-agronomic-characteristics__icon {
		position: relative;
		width: 5.7rem;
		height: 5.7rem;
		margin-bottom: 2rem;

		img {
			max-width: 80%;
			max-height: 80%
		}
	}

	.morphological-agronomic-characteristics__label {
		font-size: .8rem
	}

	.morphological-agronomic-characteristics__value {
		font-size: 1.4rem
	}

	.product__characteristics {
		padding-top: 2rem;
		padding-bottom: 1px;
	}

	.product-characteristics__item {
		width: 100%;
		padding: 0;
		margin-bottom: 3.1rem
	}

	.product-characteristics {
		margin: 2.4rem 0 0 1.1rem;
	}

	.product-characteristics__value {
		font-size: 1.6rem
	}

	.product-characteristics__icon {
		margin-right: 2rem;
		width: 1.6rem;
		height: 1.6rem;
	}

	.section-morphological-agronomic-characteristics {
		padding-top: 4rem;
		padding-bottom: .6rem;
	}

	.morphological-agronomic-characteristics {
		margin: 6.5rem -1.5rem 0;
	}

	.morphological-agronomic-characteristics__item {
		padding: 0 1.5rem;
		margin-bottom: 3rem;
	}

	.section-profit-structure {
		padding-top: 1.2rem;
		padding-bottom: 4.6rem;
	}

	.profit-structure {
		margin: 2rem 0 0;
	}

	.profit-structure__item {
		padding: .9rem 2rem .9rem 0;
		margin: 0 0 1.7rem;
		width: 100%;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.profit-structure__value {
		font-size: 1.4rem;
	}

	.profit-structure__label {
		margin-bottom: .7rem
	}

	.product__resistance {
		padding-top: 2rem;
		padding-bottom: 2.3rem;
	}

	.product-resistance {
		margin-top: 3.4rem;

		&__item {
			padding: .7rem 1.6rem;
		}

		&__head {
			width: 100%;
			padding-right: 0;
		}

		&__icons {
			display: none;
		}
	}

	.product-article {
		padding-top: 4rem;
		padding-bottom: .5rem;
	}

	.list-3__icon {
		margin-right: .9rem;
		width: 2.4rem;
		height: 2.4rem;
	}

	.list-3 li+li {
		margin-top: .5rem;
	}

	.product-article h3 {
		margin-top: 4rem;
	}

	.more-slider {
		margin: .9rem -.6rem 0;
	}

	.more-slider .slick-slide {
		padding: .2rem .6rem;
	}

	.box-paging {
		margin-top: 1rem;
		padding-top: 1.1rem;
	}

	.navs-slider_6 {
		margin-top: 1rem;

		.slick-arrow {
			font-size: 1rem;
			height: 4rem;
			width: 4rem;
		}
	}

	.catalog-filter {
		position: fixed;
		left: -100%;
		top: 0;
		width: 100%;
		max-width: 30rem;
		bottom: 0;
		z-index: 102;
		padding: 3.3rem 2rem 7.6rem 2rem;
		background: #fff;
		overflow: auto;
		left: -100%;
		-webkit-transition: left .8s ease;
		-o-transition: left .8s ease;
		transition: left .8s ease;
	}

	.opened-filter .catalog-filter {
		left: 0;
	}

	.opened-filter .filters:before {
		content: '';
		display: block;
		background: rgba(0, 0, 0, 0.8);
		position: fixed;
		z-index: 10;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		pointer-events: none;
	}

	.filters-wrap {
		margin: 0;
		width: 100%;
		form {
			display: block;
			width: 100%;
		}
	}

	.filter-form  {
	    max-width: 23.2rem;
	.box-field {
		margin: 0 0 1.2rem;
		width: 100%
		}
	}

	.filter-head {
		margin-bottom: 4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.filter-head__close {
		border: 2px solid;
		border-radius: 50%
	}
	.filter-head_main {
		border-radius: .4rem;
		background: #FFF;
		box-shadow: -.2rem .2rem .4rem -.2rem rgba(24, 39, 75, 0.12), .2rem .4rem .4rem -.2rem rgba(24, 39, 75, 0.08);
		padding: .8rem 1.6rem;
		margin-bottom: 2rem;

	}

	.filter-head__title {
		font-weight: bold;
		font-size: 1.4rem;
		line-height: 120%;
	}

	.filter-head__ic {
		font-size: 1.6rem
	}

	.opened-filter .content {
		position: relative;
		z-index: 11110;
	}

	.catalog-filter {
		.filter-head__title {
			text-transform: uppercase;
			font-size: 1.6rem
		}

		.ms-drop {
			position: relative;
		}
	}

	.exellence-about {
		margin: 0;

		&__item {
			width: 100%;
			margin: 0 0 2.4rem;
		}
	}

	.section-mission {
		padding: 2.2rem 0 0;
		position: relative;
	}

	.mission {
		display: block;
		padding: 4rem 2rem 3rem;
	}

	.mission__head {
		padding-right: 0;
		width: 100%;
	}

	.mission__description {
		width: 100%;
	}

	.about-quote .h2 {
		font-size: 1.6rem;
		margin-bottom: 2.5rem;
	}

	.about-quote__icon {
		font-size: 8rem
	}

	.section-about-quote .wrapper {
		z-index: 1;
		padding: 8rem 0 3rem;
		min-height: 20rem;
	}

	.section-about-quote .about-quote__icon {
		left: 3%;
		top: -4.3rem;
	}

	.our-activity {
		padding: 4rem 2rem 3rem;

		.h2 {
			text-align: left
		}
	}

	.section-our-activity {
		padding-bottom: 0
	}

	.our-activity__description {
		columns: 1;
		margin-top: 0
	}

	.decor-16,
	.decor-13 {
		display: none
	}

	.section-gallery {
		padding: 1.9rem 0 3.8rem;
	}

	.section-exellence-about {
		padding: 4.1rem 0 4rem;
	}

	.exellence-about__img {
		width: 20.4rem;
		max-width: 86%;
		margin-left: auto;
		margin-right: auto;
	}

	.exellence-about__title {
		font-size: 1.8rem;
	}

	.exellence-about__text {
		margin-top: .8rem;
	}

	.exellence-about__description {
		padding: 16.8rem 2.2rem 1.7rem 2.2rem;
	}

	.tab.shown .tab-content {
		padding-bottom: 2rem
	}

	.how-agroservice {
		columns: 1;
	}

	.how-agroservice__title {
		font-size: 1.9rem;
	}

	.autoComplete_wrapper>input {
		height: 3.6rem;
		background-size: 2rem 2rem;
		background-position: right 1.45rem top .7rem;
	}

	.box-paging-load {
		margin-top: .8rem;
	}

	.section-product+.section-more-products {
		background: transparent;
	}

	.distributor+.box-paging-load {
		margin-top: 1.9rem;
	}

	.distributor+.box-paging-load+.box-paging {
		margin-top: 1.2rem;
		padding-top: 2rem;
	}

	.map-3 {
		height: 45rem;
	}

	.agroservices-tabs_main {
		margin: 1rem auto 0;
	}

	.exellence-agroservice {
		margin-top: 4.5rem;
		padding: 4.2rem 2rem 3rem;
	}

	.exellence-agroservice .h2 {
		margin-bottom: 2rem;
		font-size: 2.2rem;
	}

	.how-agroservice__head {
		margin-top: -4rem;
	}

	.how-agroservice__count {
		font-size: 7.4rem;
	}

	.how-agroservice__item {
		margin: 0 0 4.3rem;
		padding: 0 2rem 2rem;
	}

	.section-how-agroservice {
		padding: 4.2rem 0 1.2rem;
	}

	.agroservices-tabs_demo {
		margin-left: -2rem;
		margin-right: -2rem;

		.tab-content {
			padding-left: 2rem;
			padding-right: 2rem
		}

		.map-1 {
			margin-left: -2rem;
			margin-right: -2rem;
			width: auto;
			height: 60rem
		}
	}
	.agroservices-demo+.agroservices-demo {
		margin-top: 3.1rem;
	}

	.agroservices-demo {
		margin-bottom: 0
	}

	.decor-14, .decor-14_2 {
		display: none;
	}
	.marker-header {
		font-size: 1.6rem
	}
	.marker-text {
		font-size: 1.4rem
	}
	.product-resistance__head {
		font-size: 1.4rem
	}
	.representatives-list .slick-track {
		display: block;
	}
	.section-seo {
	    padding: 5rem 0 3.9rem;
	}
	.block-text h2 {
	    font-size: 1.8rem;
	}
	.more_btn_2 {
		font-size: 1.6rem
	}
}

@media screen and (min-width: 1024px) {
	.navs-slider_6 {
		position: absolute;
		right: 0;
		bottom: 100%;
		margin-bottom: 4rem;
	}

	.slider-6-wrap {
		position: relative;
	}
}

@media screen and (min-width: 1921px) {
	.mission, .our-activity {
	    max-width: 83%;
	}
	.section-about-quote {
		background-size: cover;
	}
}